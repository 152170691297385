
.title-container h1 {
    padding: 0;
    margin: 0;
    color: #f7f7f8 !important;
    text-transform: uppercase;
    letter-spacing: 2px;
}

table {
    width: 100%;
    text-align: left;
}

mat-sidenav-container {
    height: 100%;
  }

  mat-sidenav, mat-sidenav-content {
      padding: 16px;
  }

  mat-sidenav {
      background-color: white;
      width: 220px;
  }

  ul {
    list-style-type: none;
    text-align: left;
  }

  a {
    text-decoration: none;
  }

  .mat-raised-button {
    background-color: #1c2453;
    color: white;
  }
